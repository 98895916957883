<template>
  <header>
    <nav class="navbar navbar-expand-xl navbar-light">
      <img src="@/assets/logo.png" class="navbar-logo" alt="Vodoterm logo" @click="navigateHome()"/>
      <img src="@/assets/logo-small.png" class="navbar-logo-small" alt="Vodoterm logo" @click="navigateHome()"/>
      <div>
        <button
          class="navbar-toggler"
          type="button"
        >
          <svg
            @click="openMobileNavbar"
            class="nav-menu-icon"
            :class="{ play: navbarOpen, unplay: !navbarOpen }"
          ></svg>
        </button>
      </div>

      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarToggler"
      >
        <ul class="navbar-nav mr-auto mt-2 mt-xl-0">
          <li class="nav-item active"> 
            <RouterLink 
              class="nav-link"
              :to="'/admin/dashboard'"
              >Početna</RouterLink
            >
            <div class="anker-underline"></div>
          </li>
          <li class="nav-item active"> 
            <RouterLink 
              class="nav-link"
              :to="'/admin/dashboard/logs'"
              >Logs</RouterLink
            >
            <div class="anker-underline"></div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="header-space"></div>
  </header>
  <AdminMobileNavbar v-if="navbarOpen" @closed="navbarOpen = false" :isVisible="navbarOpen"/>
</template>

<script>
import AdminMobileNavbar from './AdminMobileNavbar.vue'
import { ref } from "vue";
import { useRouter } from 'vue-router';
export default {
  components: { AdminMobileNavbar,},
  setup() {
    const router = useRouter();
    const navbarOpen = ref(false);
    const wishListOpen = ref(false);

    const navigateHome = () => {
      router.push({ path: '/admin/dashboard' });
    }

    const openMobileNavbar = () => {
      navbarOpen.value = !navbarOpen.value;
    }

    return {
      navigateHome,
      navbarOpen,
      wishListOpen,
      openMobileNavbar
    };
  },
};
</script>

<style lang="scss" scoped>

.header-space{
  height: 70px;
  width: 100%;
}

.navbar {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 70px;
  padding: 20px 0px;
  background-color: white;
  border-bottom: 1px solid color(lightgrey);
}

.nav-link:hover {
  color: color(blue);
}

.router-link-exact-active {
  color: color(blue) !important;
  font-weight: bold;
}

@include media(">=desktop-small") {
  .router-link-exact-active + .anker-underline {
    height: 5px;
    background: color(blue);
    top: 23px;
    position: relative;
    width: 70%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.navbar-logo {
  height: 30px;
  margin-left: $grid-margin-width;
  cursor: pointer;
  @include media(">=desktop-small") {
    margin-right: 50px;
  }

  @include media("<phone-small") {
    display: none;
  }
}

.navbar-logo-small{
  height: 30px;
  margin-left: $grid-margin-width;
  cursor: pointer;
  @include media(">=phone-small") {
    display: none;
  }
}

.navbar-toggler {
  margin-right: $grid-margin-width;
  padding: 0px;
  border: 0px;
  box-shadow: none !important;
}

.nav-menu-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  @include background-svg($nav-menu-icon);

  animation-duration: 300ms;
  animation-timing-function: steps(18);
  animation-fill-mode: forwards;
}

.navbar-collapse {
  padding-right: $grid-margin-width;
}

  .navbar-nav{
    height: 29px;
  }

.pointer {
  cursor: pointer;
}

.nav-item {
  margin-right: 30px;

  @include media("<desktop-small") {
    margin-right: 0px;
  }
}

.nav-item:last-child {
  margin-right: 0px;
}

.nav-link {
  padding: 0px !important;

  @include media("<desktop-small") {
    display: flex;
    justify-content: flex-end;
    margin: 3px 0;
  }
}

.wrapper {
  justify-content: end;
  align-items: center;
  @include media("<desktop-small") {
    display: none !important;
  }
}

.wish-list-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  @include background-svg($wish-list-icon);

  animation-duration: 500ms;
  animation-timing-function: steps(18);
  animation-fill-mode: forwards;
}

  .play {
    @keyframes play18 {
      0% {
        background-position: 0px 0px;
      }
      100% {
        background-position: -432px 0px;
      }
    }
    animation-name: play18;
  }

</style>
