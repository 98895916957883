<template>
    <div class="mobile-side-navbar">
        <BaseBackdrop @click="closeMobileNav" :topPosition="'70'" class="backdrop" :class="{'backdrop-visible': isVisible}" />
        <div class="mobile-nav" :class="{'slide-open-mobile-navbar': isVisible}">
            <ul class="main-menu">
                <li class="menu-item">
                    <RouterLink class="router-link" @click="closeMobileNav" :to="'/admin/dashboard'">
                        Pocetna strana
                    </RouterLink>
                </li>
                <li class="menu-item">
                    <RouterLink class="router-link" @click="closeMobileNav" :to="'/admin/dashboard/logs'">
                        Logs
                    </RouterLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import BaseBackdrop from "@/components/shared/BaseBackdrop.vue";
export default {
    components: { BaseBackdrop},
    emits: ["closed"],
    props: {
        isVisible: Boolean
    },
    setup(props, context) {

        const closeMobileNav = () => {
            context.emit('closed');
        }

        return {
            closeMobileNav,
        }
    },
}
</script>

<style scoped lang="scss">


.router-link {
    text-decoration: none;
    color: color(blue);
}

.light-gray {
    transition: all 0.2s ease-in;
    background: color(lightgrey);
    border: 0;
}

.bg-blue {
    transition: all 0.2s ease-in;
    background: color(blue);
    height: 54px;
}

.menu-opener-right {
  cursor: pointer;
  width: 9px;
  height: 16px;
  @include background-svg($menu-opener-right-arrow);
}

.meny-opener-down {
  cursor: pointer;
  width: 16px;
  height: 9px;
  @include background-svg($menu-closer-down-arrow);
}

.mobile-side-navbar{
    @include media(">=desktop-small"){
        display: none;
    }
}

    .backdrop{
        display: none;
    }

    .backdrop.backdrop-visible{
        display: block;
    }

    .mobile-nav {
        position: fixed;
        top: 70px;
        right: 0;
        bottom: 0;
        z-index: 100;
        overflow: hidden;
        overflow-y: auto;
        width: 340px;
        background: white;
        transform-origin: center right;
        transition: transform 0.2s;
        transform: scale(0, 1);

        @include media("<phone-medium"){
            width: 75%;
        }
    }

        ul {
            padding: 0!important;
            margin: 0;
        }

        ul li {
            list-style-type: none;
            border-bottom: 1px solid color(lightgrey);
        }


        .menu-item {
            padding: 15px 26px;
            color: color(blue);
        }

            .sub-menu-opener {
                border-left: 1px solid color(lightgrey);
                height: 100%;
                width: 54px;
                height: 54px;
                cursor: pointer;
            }

        .categories{
            transform-origin: top center;
            transition: all 0.2s;
            overflow: hidden;
            transform: scale(1, 0);
            max-height: 0px;
        }

        .categories.categories-open{
            transform: scale(1, 1);
            max-height: 1000px;
        }

            .menu-item-child{
                padding: 15px 26px;
                color: color(darkgrey);
            }

    .mobile-nav.slide-open-mobile-navbar{
        transform: scale(1, 1);
    }

</style>